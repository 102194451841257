
// Function to detect 403/Forbidden X-Cache=Error from cloudfront problem when an update occurs, and the user has
// not yet fetched a dynamic resource. (the () => import (module) structure below)
import { Notify } from 'quasar'

const xmlError = (error) => {
    console.log("Received import() error:", error);
    Notify.create({
      message: 'Could not access fueltrace.net -- reloading page in 10 seconds...',
      caption: 'This may be a temporary network issue',
      spinner: true,
      spinnerColor: 'black',
      spinnerSize: '60px',
      position: "center",
      color: 'yellow-6',
      textColor: 'black',
      classes: 'glossy',
      timeout: 10000,
      progress: true,
    })
    setTimeout(() => {
      location.reload();
    }, 10000)
}

const routes = [
  {
    path: "/",
    name: "root",
    component: () => import("layouts/MainLayout.vue").catch(xmlError),
    children: [
      {
        name: "home",
        path: "home",
        component: () => import("pages/Index.vue").catch(xmlError),
      },
      {
        name: "profile",
        path: "profile",
        component: () => import("pages/Profile.vue").catch(xmlError),
      },
      {
        name: "users",
        path: "users",
        component: () => import("pages/admin/Users.vue").catch(xmlError),
      },
      {
        name: "inventory",
        path: "inventory",
        component: () => import("pages/admin/Inventory.vue").catch(xmlError),
      },
      {
        name: "employees",
        path: "employees",
        component: () => import("pages/admin/Employees.vue").catch(xmlError),
      },
      {
        name: "reporting",
        path: "reporting",
        component: () => import("pages/admin/Reporting.vue").catch(xmlError),
      },
      // {
      //   name: "search",
      //   path: "search",
      //   component: () => import("pages/admin/Search.vue").catch(xmlError),
      // },
      // {
      //   name: "search",
      //   path: "/search/:searchElement/",
      //   props: true,
      //   component: () => import("pages/admin/Search.vue").catch(xmlError),
      // },
      {
        name: "search",
        path: "/search/:searchElement/subject/:subject?/filter/:filter?/from/:from?/to/:to?",
        props: true,
        component: () => import("pages/admin/Search.vue").catch(xmlError),
      },
      {
        name: "billing",
        path: "billing",
        component: () => import("pages/admin/Billing.vue").catch(xmlError),
      },
      {
        name: "dealership",
        path: "dealership",
        component: () => import("pages/admin/Dealership.vue").catch(xmlError),
      },
      {
        name: "notifications",
        path: "notifications",
        component: () => import("pages/admin/Notifications.vue").catch(xmlError),
      },
      {
        name: "dashboard",
        path: "dashboard",
        component: () => import("pages/admin/Dashboard.vue").catch(xmlError),
      },
    ],
  },

  {
    path: "/report-node",
    name: "report-node",
    component: () => import("layouts/ReportNode.vue").catch(xmlError),
    children: [
      {
        name: "billingStatement",
        path: "billingStatement/id/:statementId",
        props: true,
        component: () => import("pages/reports/billing/BillingStatement.vue").catch(xmlError),
      },
      {
        name: "genericReport",
        path: "genericReport/from/:from/to/:to",
        props: true,
        component: () => import("pages/reports/generic/GenericReport.vue").catch(xmlError),
      },
      {
        name: "costExceedsAuthReport",
        path: "costExceedsAuth/from/:from/to/:to",
        props: true,
        component: () => import("pages/reports/fraud/CostExceedsAuth.vue").catch(xmlError),
      },
      {
        name: "convertibleAdHoc",
        path: "convertibleAdHoc/from/:from/to/:to",
        props: true,
        component: () => import("pages/reports/fraud/ConvertibleAdHoc.vue").catch(xmlError),
      },
      {
        name: "unConvertibleAdHoc",
        path: "unConvertibleAdHoc/from/:from/to/:to",
        props: true,
        component: () => import("pages/reports/fraud/UnConvertibleAdHoc.vue").catch(xmlError),
      },
      {
        name: "exceedMaxAuthsPerVehicle",
        path: "exceedMaxAuthsPerVehicle/from/:from/to/:to",
        props: true,
        component: () => import("pages/reports/fraud/ExceedMaxAuthsPerVehicle.vue").catch(xmlError),
      },
      {
        name: "allTransactions",
        path: "allTransactions/from/:from/to/:to",
        props: true,
        component: () => import("pages/reports/general/AllTransactions.vue").catch(xmlError),
      },
      {
        name: "departmentSplit",
        path: "departmentSplit/from/:from/to/:to",
        props: true,
        component: () => import("pages/reports/general/DepartmentSplit.vue").catch(xmlError),
      },
    ]
  },

  {
    path: "/user",
    name: "user",
    component: () => import("layouts/BlankLayout.vue").catch(xmlError),
    children: [
      {
        name: "login",
        path: "login",
        component: () => import("pages/Login.vue").catch(xmlError),
      },
    ],
  },

  {
    path: "/fs",
    name: "fs",
    component: () => import("layouts/FuelStationLayout.vue").catch(xmlError),
    children: [
      {
        name: "fshome",
        path: "home",
        component: () => import("pages/fuelstation/Summary.vue").catch(xmlError),
      },
      {
        name: "fsprofile",
        path: "profile",
        component: () => import("pages/Profile.vue").catch(xmlError),
      },
    ],
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: "/:catchAll(.*)*",
    name: "catchAll",
    component: () => import("pages/Error404.vue").catch(xmlError),
  },
];

export default routes;
